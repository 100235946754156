*{
    margin: 0px;
    padding: 0px;
    -webkit-tap-highlight-color:transparent;
    -webkit-touch-callout: none;
    // -webkit-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
}
html,body,.pageCont{ width: 100%;  position: relative;}
img{ display: block; width: 100%;}
.pos{ position: absolute; top: 0px; left: 0px;}
