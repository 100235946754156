
.pageCont{
    width: 100%;
    min-height: 100vh;
    padding-bottom: 0.5rem;
    position: relative;
    padding-top: 1px;
    background-image: url("https://havas-1310487634.cos.ap-beijing.myqcloud.com/web/biaoqingbao/bg.jpg");
    background-size: 100%;
    background-position: left top;
    background-repeat: repeat-y;
    opacity: 0;
    .logos{
        width: 90%;
        margin: 0.3rem auto 0.5rem;
    }
    .gifcont{
        width: 92%;
        margin: 0px auto;
        background-color: white;
        padding: 1px;
        border-radius: 0.3rem;
        .gifTitels{
            width: 90%;
            margin: 0.3rem auto 0;
        }
        .gifcontainer{
            width: 90%;
            margin: 0px auto 0.5rem;
            display: flex;
            align-content: flex-start;
            flex-wrap: wrap;
            justify-content:flex-start;;
            .gifmodel{
                display: block;
                width: 48.5%;
                margin: 0.2rem 3% 0 0;
                position: relative;
                .di{
                    opacity: 0;
                }
                .gifImg{
                    width: 88.6%;
                    position: absolute;
                    top: 4%;
                    left: 5.5%;
                }
                .kuang{
                    position: absolute;
                    width: 100%;
                    top: 0px;
                    left: 0px;
                    pointer-events: none;
                }
                .gifSent{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    opacity: 0;
                }
                &:nth-child(even){
                    margin-right: 0px;
                }
            }
        }
    }
}


